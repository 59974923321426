<script>
import { defineComponent, watch, reactive, ref, computed, getCurrentInstance } from 'vue' // , nextTick, onBeforeUpdate
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

/**
 * @template T
 * @typedef {import('@vue/composition-api').Ref<T>} Ref<T>
 */
/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */

export default defineComponent({
  props: {
    warehouses: {
      type: Array,
      required: true,
    },
    catalogItems: {
      type: Array,
      required: true,
    },
    /** @type {ProductFormModel['inventories']} */
    value: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $route } = getCurrentInstance().proxy.$root
    const catalogues = computed(() => {
      const result = new Map()
      props.catalogItems.forEach(item => {
        result.set(item.id, item)
      })
      return result
    })
    const getCatalogue = (inventoryCatalog) => {
      return catalogues.value.get(inventoryCatalog.id) || {}
    }
    const getCatalogProperty = (inventoryCatalog, property, defaultValue = null) => {
      return getCatalogue(inventoryCatalog)[property] ?? defaultValue
    }

    const warehouses = computed(() => {
      const result = new Map()
      props.warehouses.forEach(w => {
        result.set(w.warehouse_id, w)
      })
      return result
    })
    const getWarehouseProperty = (inventory, property, defaultValue = null) => {
      const warehouse =  warehouses.value.get(inventory.id)
      if (!warehouse) return defaultValue

      return warehouse[property] ?? defaultValue
    }

    const emitInventory = () => {
      // eslint-disable-next-line no-unused-vars
      emit('input', productInventories.value.map(({ warehouse, ...record }) => ({
        ...record,
        catalogs: record.catalogs.map(({ inventory }) => ({
          ...inventory,
        })),
      })))
    }

    const debounceEmit = debounce(emitInventory, 250)

    const remapInventories = (inventories) => {
      return [...inventories].map(inventory => {
        return {
          ...inventory,
          catalogs: generateCatalogues(inventory),
        }
      })
    }
    const generateCatalogues = (inventory) => {
      return props.catalogItems
        .filter(c => c.id || c.status)
        .map((item, index) => {
          // console.log('generateCatalogues item: ', item)
          const id = item.id || 'TEMP_CATALOG_ID_' + index
          /** @NOTES : MN-231 - Problem here */
          const existing = inventory?.catalogs.find((catalog) => catalog.id === item.id || catalog.inventory?.id === item.id || catalog.id === id)
          const catalogInventoryItem = existing?.inventory || existing || {
            id, // item.id
            uom: null,
            pre_order_status: false,
          }

          catalogInventoryItem.uom = catalogInventoryItem?.uom || item.price?.uom || null
          
          return {
            inventory: catalogInventoryItem,
            // item,
            item: {
              ...item,
              id,
              on_hand: catalogInventoryItem?.on_hand || 0,
              availability: catalogInventoryItem?.avail || 0,
            },
          }
        })
    }

    /** @type {Ref<ProductFormModel['inventories']} */
    const productInventories = ref([])
    watch(() => props.value, (inventories) => {
      productInventories.value = remapInventories(inventories || [])
    }, { immediate: true })
    watch(() => props.catalogItems, () => {
      const value = remapInventories(productInventories.value)
      // ???
      // const lengthChanged = value.length !== productInventories.value.length || value.some((item, index) => item.catalogs.length !== productInventories.value[index].catalogs.length )

      if (!isEqual(productInventories.value, value)) {
        productInventories.value = value

        emitInventory()
      }

    }, { deep: true })

    
    const addInventoryModal = reactive({
      open: false,
      warehouse_id: null,
    })
    /**
     * @param {import('@/types/warehouse').Warehouse} warehouse
     */
    const addInventory = () => {
      const warehouseId = addInventoryModal.warehouse_id
      
      if (!warehouseId) {
        return
      }

      const warehouse = props.warehouses.find(w => w.warehouse_id === warehouseId)

      productInventories.value.push({
        warehouse,
        id: warehouse.warehouse_id,
        is_main: productInventories.value.filter(i => i.is_main).length === 0,
        catalogs: generateCatalogues(),
      })
      debounceEmit()

      addInventoryModal.open = false
      addInventoryModal.warehouse_id = null
    }

    const removeInventory = (inventory) => {
      productInventories.value = productInventories.value.filter(i => i.id !== inventory.id)
      if (!productInventories.value.find(i => i.is_main) && productInventories.value.length) {
        productInventories.value[0].is_main = true
      }

      debounceEmit()
    }

    const selectedWarehouseIds = computed(() => {
      return productInventories.value.map(i => i.id)
    })
    const availableWarehouses = computed(() => {
      return props.warehouses.filter(w => !selectedWarehouseIds.value.includes(w.warehouse_id))
    })

    const onMainWarehouseSelected = (warehouseId) => {
      productInventories.value.forEach(i => {
        i.is_main = i.id === warehouseId
      })
      debounceEmit()
    }

    const togglePreOrderStatus = (catalog, checked) => {
      catalog.inventory.pre_order_status = checked
      debounceEmit()
    }

    return {
      productInventories,
      addInventoryModal,
      addInventory,
      removeInventory,
      selectedWarehouseIds,
      availableWarehouses,
      onMainWarehouseSelected,
      getCatalogue,
      getCatalogProperty,
      getWarehouseProperty,
      togglePreOrderStatus,
    }
  },
})
</script>

<template>
  <div>
    <a-card
      v-for="(inventory, index) in productInventories"
      :key="index"
      class="ant-card ant-card-bordered inventory-card mb-4"
    >
      <template #title>
        <div class="">
          <div>{{ getWarehouseProperty(inventory, 'name', inventory.id) }}</div>
        </div>
      </template>

      <template #extra>
        <a-radio
          :checked="inventory.is_main"
          :value="inventory.id"
          name="main_warehouse[]"
          :disabled="$route.query.edit || $route.query.master_product ? true : false"
          @input="(value) => onMainWarehouseSelected(inventory.id)"
        >
          Jadikan Gudang Utama
        </a-radio>
        <a-popconfirm
          v-if="!$route.query.edit && !$route.query.master_product"
          title="Apakah Anda yakin menghapus warehouse ini dari daftar inventory?"
          @confirm="removeInventory(inventory)"
        >
          <a-button
            type="link"
            icon="delete"
            :disabled="$route.query.edit || $route.query.master_product ? true : false"
            class="text-danger"
          >
            <a-icon type="trash" />
          </a-button>
        </a-popconfirm>
      </template>

      <a-collapse default-active-key="1" expand-icon-position="right">
        <a-collapse-panel key="1">
          <template #header>
            <b>
              Stock Produk
            </b>
          </template>
          
          <div class="table-responsive m-0">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th rowspan="2" style="vertical-align: middle">
                    SKU
                  </th>
                  <th v-if="catalogItems.length > 1" rowspan="2">
                    Nama
                  </th>
                  <th colspan="2" width="180" class="text-center">
                    Stok
                  </th>
                  <th rowspan="2" width="100" style="vertical-align: middle">
                    Satuan
                  </th>
                  <th rowspan="2" width="100" class="text-center" style="vertical-align: middle">
                    Preorder
                  </th>
                </tr>
                <tr>
                  <th width="90" class="text-center">
                    Availability
                  </th>
                  <th width="90" class="text-center">
                    On Hand
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(catalog, catalogIndex) in inventory.catalogs" :key="catalog.id || catalogIndex" class="">
                  <td>
                    <div v-if="catalog.item.sku">
                      {{ catalog.item.sku?.toUpperCase() }}
                    </div>
                    <div v-else-if="catalog.item && !catalog.item.sku" style="font-size: 70%; color: #999">
                      (not set)
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                  <td v-if="catalogItems.length > 1">
                    {{ catalog.item.title }}
                  </td>
                  <td class="text-center">
                    {{ catalog.item.availability || 0 }}
                  </td>
                  <td class="text-center">
                    {{ catalog.item.on_hand || 0 }}
                  </td>
                  <td>{{ catalog.inventory.uom }}</td>
                  <td class="text-center">
                    <a-tooltip title="Preorder">
                      <a-switch
                        size="small"
                        :disabled="$route.query.edit || $route.query.master_product ? true : false"
                        :checked="catalog.inventory.pre_order_status"
                        @change="(checked) => togglePreOrderStatus(catalog, checked)"
                      />
                    </a-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-card>
    
    <a-button
      v-if="availableWarehouses.length"
      type="primary"
      icon="plus"
      :disabled="$route.query.edit || $route.query.master_product ? true : false"
      ghost
      @click="addInventoryModal.open = true"
    >
      Tambah Gudang
    </a-button>

    <a-modal v-model="addInventoryModal.open" :footer="false" title="Tambah Lokasi Gudang">
      <a-select
        v-model="addInventoryModal.warehouse_id"
        show-search
        :filter-option="(input, option) => option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0"
        class="w-100 h-48px mb-4"
        placeholder="Pilih lokasi gudang"
      >
        <a-select-option
          v-for="warehouse in availableWarehouses" 
          :key="warehouse.warehouse_id"
          :value="warehouse.warehouse_id"
        >
          {{ warehouse.name }}
        </a-select-option>
      </a-select>

      <div class="d-flex mx-n2">
        <a-button
          type="link"
          size="large"
          block
          class="mx-2"
          @click="addInventoryModal.open = false"
        >
          Batal
        </a-button>
        <a-button
          type="primary"
          size="large"
          block
          class="mx-2"
          :disabled="!addInventoryModal.warehouse_id"
          @click="addInventory"
        >
          Terapkan
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
