<script>
import { computed, defineComponent, ref, watch } from 'vue'
import FormItem from '@/components/Input/FormItem.vue'
import Price from './Price/index.vue'
import BundleProductInput from '../BundleProductInput/index.vue'

export default defineComponent({
  components: {
    FormItem,
    Price,
    BundleProductInput,
},
  props: {
    business: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
    },
    flag: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const model = ref(props.value)
    watch(() => props.value, (value) => {
      model.value = value
    }, { immediate: true })
    const item = computed({
      get() {
        return model.value.catalogs.items[0] || {}
      },
      set(value) {
        model.value.catalogs.items[0] = value
      },
    })

    return {
      model,
      item,
    }
  },
})
</script>

<template>
  <div>
    <div>
      <div>
        <div v-if="$route.query.edit">
          <a-col class="mb-2" :span="6">
            <div class="title-detail d-flex justify-content-start mb-2">
              {{ $t('SKU') }}
            </div>
          </a-col>
          <a-col class="mb-2" :span="18">
            <div class="mb-2">
              : {{ item.sku }}
            </div>
          </a-col>
        </div>
        <a-row v-else type="flex" :gutter="16">
          <a-col :lg="{ span: 12 }">
            <FormItem rules="required|no_space" label="SKU">
              <a-input 
                v-model="item.sku"
                class="h-48px"
                :disabled="$route.query.edit || $route.query.master_product ? true : false"
              />
            </FormItem>
          </a-col>
          <!-- <a-col :lg="{ span: 12 }">
            <FormItem rules="" :label="$t('product.factory_sku')">
              <a-input 
                v-model="item.factory_sku"
                class="h-48px"
              />
            </FormItem>
          </a-col> -->
        </a-row>
      </div>

      <Price
        v-model="item.price"
        :edit="edit"
        :business="business"
        :units="model.units"
        :vat-type="model.detail.vat_type" 
        :flag="flag"
        :model="model"
        class="mb-5"
        @handleEdit="$emit('handleEdit')"
      />
    </div>

    <BundleProductInput
      v-model="model.catalogs.bundling"
      :edit="edit"
      :product="value"
      :business="business"
      class="mb-3"
    />
  </div>
</template>
