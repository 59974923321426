<script>
import { defineComponent, ref, watch } from 'vue'
import FormItem from '@/components/Input/FormItem.vue'
import Price from '@/components/Store/Redeem/ProductForm/TypeAndPrice/Price/index.vue'
import SelectPhoto from './SelectPhoto.vue'
import { ValidationProvider } from 'vee-validate';

export default defineComponent({
  components: {
    FormItem,
    Price,
    SelectPhoto,
    ValidationProvider,
  },
  props: {
    initialCollapsed: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    units: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    fixUnit: {
      type: Boolean,
      default: false,
    },
    skuExcludesRule: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      required: false,
    },
    flag: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const catalog = ref(props.value)
    watch(() => props.value, () => {
      catalog.value = props.value
    }, { deep: true, immediate: true })
    watch(catalog, () => emit('input', catalog.value), { deep: true })

    const show = ref(String(props.initialCollapsed ? '' : '1'))
    watch(show, () => emit('collapsed', !!show.value))
    const toggleShow = () => {
      show.value = show.value === '1' ? '' : '1'
    }

    const truncated = (e) => {
      return e.length > 17 ? e.substring(0, 17) + '...' : e;
    }

    return {
      catalog,
      show,
      toggleShow,
      truncated,
    }
  },
})
</script>

<template>
  <a-collapse :active-key="show" expand-icon-position="right" :expand-icon="null" :class="{ collapsed: !show }">
    <div class="variant-table--summary">
      <div v-for="(optionName, index) in Object.values(catalog.option)" :key="index" class="option-name">
        {{ optionName }}
      </div>
      <div class="ml-auto">
        <span v-if="catalog.sku">
          <a-tooltip :title="catalog.sku">
            {{ truncated(catalog.sku) }}
          </a-tooltip>
        </span>
        <span v-else style="font-size: 80%; color: #bbb">
          (Not set)
        </span>
      </div>
      <div class="actions">
        <a-switch
          :checked="catalog.status"
          size="small"
          :disabled="true"
          @change="(val) => {
            catalog.status = val
            $emit('input:status', val)
          }"
        />
      </div>
      <div class="caret">
        <button type="button" @click="toggleShow">
          <a-icon type="caret-right" :rotate="!!show ? 90 : 0" />
        </button>
      </div>
    </div>
    <a-collapse-panel key="1" class="variant-table--item" :show-arrow="false" force-render>
      <div class="variant-table--detail">
        <div class="d-flex">
          <div class="mr-4" style="width: 125px">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: catalog.status }"
              :custom-messages="{
                required: 'Pilih minimal 1 gambar produk'
              }"
              immediate
              :name="`Gambar ${value.title}`"
              tag="div"
            >
              <SelectPhoto
                v-model="catalog.images"
                :images="images"
                :class="{ 'has-feedback has-error': errors.length }"
              />
              <div v-if="errors.length" class="text-danger text-small mt-2 ant-form-explain">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
          <div class="w-100">
            <div v-if="$route.query.edit" class="mt-4">
              <a-col :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  Nama
                </div>
              </a-col>
              <a-col :span="18">
                <div class="text-left mb-2">
                  : {{ catalog.title }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  SKU
                </div>
              </a-col>
              <a-col :span="18">
                <div class="text-left mb-2">
                  : {{ catalog.sku }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  Status
                </div>
              </a-col>
              <a-col :span="18">
                <div class="text-left mb-2">
                  : {{ catalog.status ? 'Aktif' : 'Tidak Aktif' }}
                </div>
              </a-col>
            </div>
            <a-row v-else type="flex" :gutter="16" class="mb-3">
              <a-col :xl="{ span: 24 }">
                <FormItem
                  :rules="{ required: catalog.status, no_space: true, excluded: skuExcludesRule }"
                  :custom-messages="{
                    excluded: 'SKU tidak boleh sama dengan varian lainnya'
                  }"
                  label="SKU"
                  :name="`SKU [${value.title}]`"
                >
                  <a-input v-model="catalog.sku" :disabled="$route.query.master_product ? true : false" class="h-48px" />
                </FormItem>
              </a-col>
            </a-row>

            <Price
              v-model="catalog.price"
              :edit="edit"
              :catalog="catalog"
              :flag="flag"
              :vat-type="product.detail.vat_type"
              :business="business"
              :units="units"
              :unit="fixUnit ? catalog.price.uom : null"
              :label="`Harga Produk ${catalog.sku}`"
              @changeuom="val => $emit('changeuom', val)"
            />
          </div>
        </div>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<style scoped lang="scss">
.caret {

  >div,
  >button {
    background-color: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }
}
</style>
