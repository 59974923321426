<script>
/**
 * @template T
 * @typedef {import('@vue/composition-api').Ref<T>} Ref
 */

/**
 * @typedef {import('@/types/product').ProductFormModel} ProductFormModel
 */
import { defineComponent, ref, watch, computed, getCurrentInstance } from 'vue'
import { validate, ValidationProvider } from 'vee-validate';
import { uploadProductImage } from '@/api/product'
import { addDeleteImages } from '@/api/channels/index'

const ACCEPTED_EXTENSION = ['jpg', 'jpeg', 'png', 'bmp'];
const MAX_IMAGE = 15;
const MAX_IMAGE_SIZE_IN_KB = 1000; // 10_000
const MIN_WIDTH = 300
const MIN_HEIGHT = 300

/**
 * @param {ProductFormModel['images'][0]} image 
 */
const getImageUrl = (image) => {
  if (image.url) return `${image.url}?tr=w-200,h-200,cm-pad_resize,bg-FFFFFF`
  if (image.file) return URL.createObjectURL(image.file)

  return null
}

export default defineComponent({
  components: {
    ValidationProvider,
  },
  props: {
    value: Object,
    channel: [String, Number],
    permission: Array,
  },
  setup(props, { emit }) {
    const { $message, $route } = getCurrentInstance().proxy.$root
    /** @type {Ref<ProductFormModel>} */
    const model = ref(props.value)
    const edit = ref(false)
    watch(model, () => emit('input', model.value))
    watch(() => props.value, () => {
      model.value = props.value
    })

    /** @type {Ref<ProductFormModel['images']>} */
    const images = ref(props.value.images || []);
    watch(() => props.value.images, (value) => {
      images.value = value || [];
    }, { deep: true });
    const activeImages = computed(() => images.value.filter(({ deleted }) => !deleted));

    const isCanAddImage = computed(() => 
      ($route.name === 'product.channel.distributor' || $route.query.master_product || edit.value) && 
      images.value.length < MAX_IMAGE && props.permission.includes('WRITE') );

    const validateDimension = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.addEventListener('load', e => {
          const { naturalWidth, naturalHeight, src } = e.target
          if (naturalWidth < MIN_WIDTH || naturalHeight < MIN_HEIGHT) {
            reject(file)
          } else {
            resolve(file)
          }
          window.URL.revokeObjectURL(src)
        })
      })
    }
    
    const onFileChanged = async (e) => {
      // e.preventDefault()
      const files = Array.from(e.target.files)
      const remainingImageCanUpload = MAX_IMAGE - images.value.length

      if (files.length > remainingImageCanUpload) {
        return $message.error(`You can upload maximum ${remainingImageCanUpload} images left`)
      }

      const results = await validateImages(files)

      const invalidImage = results.find(({ result }) => !result.valid)
      if (invalidImage) {
        $message.error(invalidImage.result.errors[0], 5)
        return
      } else {
        await Promise.all(files.map(async f => await validateDimension(f)))
          .then(() => {
            addImages(files)
          })
          .catch(() => {
            $message.error(`Dimensi foto minimal ${MIN_WIDTH}x${MIN_HEIGHT} px`)
            return
          })
      }

      e.target.value = ''
    }

    /**
     * @param {File[]} files 
     * @typedef {{result: import('vee-validate/dist/types/types').ValidationResult, file: File }} ValidationResult
     * @returns {Promise<ValidationResult[]>}
     */
    const validateImages = async (files) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const result = await validate(
            file,
            [
              `ext:${ACCEPTED_EXTENSION.join(',')}`,
              `size:${MAX_IMAGE_SIZE_IN_KB}`,
            ].join('|'), 
            { name: 'File' },
          )

          return { result, file }
        }),
      )
    }

    const addImages = async (files) => {
      images.value.push(
        ...files.map(file => ({
          file,
          uploading: false,
          failed: null,
          message: null,
          deleted: false,
        })),
      )

      if (!$route.query.edit) {
        await uploadImages()
      }
    }

    const uploadImage = async (image) => {
      let file = []
      let existing = []
      image.forEach(item => {
        item.uploading = true
        item.failed = null
        if(item.ref_id) {
          existing.push(item.url)
        } else {
          file.push(item.file)
        }
      })

      try {
        let data = null
        if($route.query.edit) {
          const response = await addDeleteImages({
            type: 'redeem',
            business_id: $route.query.business_id,
            channel_id: props.channel,
            product_id: props.value.id,
            file,
            images_existing: existing,
          })
          data = response?.data
        } else {
          const response = uploadProductImage({
            business_id: $route.query.business_id,
            product_id: props.value.id,
            file,
          })
          data = response?.data
        }

        if (data?.url || data.message === 'Success') {
          image.forEach(item => {
            if (!$route.query.edit) {
              item.file = null
            }
            item.uploading = false
            item.failed = false
          })
          if (!$route.query.edit) {
            return data
          }
        }

      } catch (err) {
        const maxUpload = 'Maximum upload size exceeded'
        const msg = err?.response?.data?.message || 'Failed'
        image.forEach(item => {
          item.uploading = false
          item.failed = msg.includes(maxUpload) ? maxUpload : msg
        })
        throw err
      }
    }

    const uploadImages = async (saveAfterUpload = true) => {
      if (!props.value.id) return // bail out when product id is null
      
      const results = await uploadImage(images.value)

      if (results?.length && saveAfterUpload) save()

      edit.value = false
      
      return results
    }

    // watch(() => props.value.id, (value) => {
    //   if (value) nextTick(() => uploadImages())
    // })

    const setAsPrimary = (index) => {
      images.value.splice(0, 0, images.value.splice(index, 1)[0]);
      save()
    }
    const setAsThumbnail = (index) => {
      images.value.splice(1, 0, images.value.splice(index, 1)[0]);
      save()
    }
    const deleteImage = (index) => {
      images.value[index].deleted = true
      save()
    }
    const save = () => {
      emit('change', images.value.map((image, index) => ({
        ...image,
        order: index,
        tags: [ 
          index === 0 ? 'SHOW' : undefined,
          index === 1 || images.value.length === 1 ? 'THUMB' : undefined,
        ].filter(Boolean),
      })))
    }

    const handleEdit = () => {
      edit.value = !edit.value
      emit('changeEditable', {photo: edit.value})
      if (edit.value === false) {
        const filter = images.value.filter(v => {return !v.url})
        if (filter.length > 0) deleteImage(activeImages.value.length -1)
        const firstErrorElement = document.querySelector('#photo-product')
        window.scrollTo({
          behavior: 'smooth',
          top: firstErrorElement.getBoundingClientRect().top + window.pageYOffset - 225,
        })
      }
    }

    return {
      model,
      images,
      activeImages,
      getImageUrl,
      isCanAddImage,
      onFileChanged,
      ACCEPTED_EXTENSION,
      setAsPrimary,
      setAsThumbnail,
      deleteImage,
      uploadImages,
      minWidth: MIN_WIDTH,
      minHeight: MIN_HEIGHT,
      edit,
      handleEdit,
    }
  },
})
</script>

<template>
  <a-card>
    <div class="mb-5 d-flex">
      <div>
        <h4 id="photo-product">
          {{ $t('product.photo_video') }}
        </h4>
      </div>
      <div v-if="$route.query.edit && !edit" class="ml-auto">
        <a-button
          type="primary"
          size="large"
          :ghost="edit ? false : true"
          @click="handleEdit"
        >
          Edit
        </a-button>
      </div>
    </div>

    <section class="mb-5">
      <div class="font-weight-semibold">
        {{ $t('product.photo_title') }}
      </div>
      <div class="text-muted mb-4">
        {{ $t('product.foto_video_description', { minWidth, minHeight }) }}
      </div>

      <div class="image-wrapper">
        <div
          v-for="(image, index) in activeImages"
          :key="`${image}_${index}`"
        >
          <div v-if="$route.query.edit && !edit" class="image-item">
            <img :src="getImageUrl(image)" />
            <div v-if="image.uploading" class="loading">
              <a-icon type="loading" spin />
            </div>
            <a-popover trigger="hover" placement="top">
              <template #content>
                <div class="failed-reason" style="font-size: 0.75rem; max-width: 350px">
                  {{ image.failed }}
                </div>
              </template>
              <div v-if="image.failed" class="failed" @click="uploadImages()">
                <a-icon type="exclamation-circle" />
                <div>Failed</div>
              </div>
            </a-popover>
            <div v-if="index === 0" class="image-label">
              Utama
            </div>
            <div v-if="index === 1" class="image-label">
              Thumbnail
            </div>
          </div>

          <a-popover
            v-else
            placement="bottom"
            :trigger="permission.includes('WRITE') ? 'hover' : 'contextmenu'"
          >
            <template #content>
              <div style="width: 150px">
                <a-button
                  v-if="index !== 0 && !image.failed"
                  type="primary"
                  size="small"
                  shape="round"
                  class="mb-2 w-100 text-small"
                  @click="setAsPrimary(index)"
                >
                  Set Utama
                </a-button>
                <a-button
                  v-if="index !== 1 && !image.failed"
                  ghost
                  type="primary"
                  size="small"
                  shape="round"
                  class="mb-2 w-100"
                  @click="setAsThumbnail(index)"
                >
                  Set Thumbnail
                </a-button>

                <a-popconfirm
                  title="Are you sure want to remove this image ?"
                  ok-text="Remove"
                  cancel-text="Cancel"
                  @confirm="deleteImage(index)"
                >
                  <a-button class="w-100" icon="delete" size="small" shape="round" />
                </a-popconfirm>
              </div>
            </template>
            <div class="image-item">
              <img :src="getImageUrl(image)" />

              <div v-if="image.uploading" class="loading">
                <a-icon type="loading" spin />
              </div>

              <a-popover trigger="hover" placement="top">
                <template #content>
                  <div class="failed-reason" style="font-size: 0.75rem; max-width: 350px">
                    {{ image.failed }}
                  </div>
                </template>
                <div v-if="image.failed" class="failed" @click="uploadImages()">
                  <a-icon type="exclamation-circle" />
                  <div>Failed</div>
                </div>
              </a-popover>

              <div v-if="index === 0" class="image-label">
                Utama
              </div>
              <div v-if="index === 1" class="image-label">
                Thumbnail
              </div>
            </div>
          </a-popover>
        </div>
        <div v-if="isCanAddImage">
          <label class="image-item uploader">
            <input
              type="file"
              class="d-none"
              multiple
              :accept="ACCEPTED_EXTENSION.map(e => `.${e}`).join(',')"
              @change="onFileChanged"
            />
            <div>
              <a-icon type="plus" />
              <div>Gambar</div>
            </div>
          </label>
        </div>
      </div>

      <ValidationProvider
        v-slot="{ errors }"
        tag="div"
        name="Foto"
        rules="required"
        :custom-messages="{
          required: 'Upload minimal 1 gambar produk',
        }"
      >
        <input type="hidden" :value="activeImages" />
        <div v-if="errors.length" class="ant-form-explain text-danger">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </section>

    <!-- <section>
      <div class="font-weight-semibold">
        {{ $t('product.video_title') }}
      </div>
      <div class="text-muted mb-3">
        {{ $t('product.video_description') }}
      </div>

      <a-input
        v-model="model.video_url"
        :disabled="$route.query.edit && !edit ? true : false"
        placeholder="https://www.youtube.com/watch?v=..."
        class="h-48px"
      />
    </section> -->

    <div v-if="$route.query.edit && edit" class="mt-3 py-3 text-right footer">
      <a-button
        size="large"
        type="primary"
        ghost
        class="px-5 mr-3 ml-auto"
        @click="handleEdit"
      >
        {{ $t('utils.cancel') }}
      </a-button>

      <a-button
        size="large"
        type="primary"
        class="px-5"
        @click="uploadImages"
      >
        {{ $t('utils.save') }}
      </a-button>
    </div>
  </a-card>
</template>

<style scoped lang="scss">
.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.75rem;
  margin-right: -.75rem;

  > * {
    padding: 0 .75rem;
  }

  .image-item {
    position: relative;
    border: 1px solid #ccc;
    border-radius: .5rem;
    overflow: hidden;
    width: 150px;
    height: 150px;
    margin-bottom: 1.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    

    &.uploader {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ccc;
      cursor: pointer;
      border: 2px dashed #ddd;

      &:hover {
        background-color: #fafafa;
      }

      .anticon {
        font-size: 2rem;
        display: inline-block;
        margin-bottom: .5rem;
      }
    }

    .image-label {
      padding: .15rem .65rem;
      background: var(--kit-color-primary);
      color: white;
      font-size: .8rem;
      position: absolute;
      bottom: 0;
      border-radius: 0 .5rem 0 0;
    }

    .loading,
    .failed {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, .2);
      
      .anticon {
        font-size: 3rem;
        color: var(--kit-color-primary);
      }
    }

    .failed {
      background-color: rgba(255, 255, 255, .9);
      .anticon {
        font-size: 2rem;
        margin-bottom: .5rem;
      }
    }
  }
}
</style>
