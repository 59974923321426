<template>
  <div>
    <div class="d-flex flex-wrap align-items-center mb-3">
      <label class="d-block font-weight-semibold">{{ $t('product.price_area') }}</label>
      <div class="ml-auto">
        <a-button type="primary" ghost @click="onOpenModal">
          <a-icon type="edit" />
          {{ $t('utils.change') }}
        </a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :row-key="(record, index) => index"
      :data-source="priceList"
      :loading="isLoading"
      :pagination="false"
      :scroll="scroll"
    >
      <template slot="price_area_id" slot-scope="text">
        {{ getName(text, 'areas') }}
      </template>
      <template slot="customer_category_id" slot-scope="text">
        {{ getName(text, 'customers') }}
      </template>
      <template slot="value" slot-scope="text, record">
        {{ format(text, record.currency) }}
      </template>
      <template slot="effective_date" slot-scope="text">
        {{ `${$moment(text).format('DD MMM YYYY')}` }}
      </template>
    </a-table>

    <EditPriceArea
      :visible="visible"
      :prices="priceList"
      :areas="areas"
      :customers="customers"
      @close="onCloseModal"
      @submit="onSubmitPrice"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { getArea, getPriceArea, getCustomerCategory, updatePriceArea } from '@/api/channels/distributor'
import { useCurrency } from '@/composable/useCurrency'
import { parseDate } from '@/utils/dateTime'
import { columnsPriceArea } from '@/data/columns'
import EditPriceArea from '../Modal/EditPriceArea.vue'

export default {
  components: {
    EditPriceArea,
  },
  setup() {
    const { format } = useCurrency()
    return { format }
  },
  data() {
    this.fetchArea = debounce(this.fetchArea, 500)
    return {
      catalogId: null,
      isLoading: false,
      visible: false,
      columns: columnsPriceArea,
      priceList: [],
      areas: [],
      customers: [],
      scroll: {
        y: 190,
      },
    }
  },
  watch: {
    areas(newValue) {
      if (newValue.length) {
        this.fetchPriceArea()
      }
    },
  },
  created() {
    this.fetchArea()
    this.fetchCustomer()
  },
  methods: {
    async fetchPriceArea() {
      this.isLoading = true
      this.priceList = []
      const { business_id } = this.$route.query
      const bodyValue = {
        id: this.$route.params.id,
        business_id,
        params: {
          product_id: this.$store.state.productChannel.productId,
          effective_date: this.$moment().format('YYYY-MM-DD hh:mm:ss'),
          // price_area_id <-- if admin
          // customer_category_id <-- if admin
        },
      }
      await getPriceArea(bodyValue)
        .then(({ data: { data: response }}) => {
          response.forEach(({ catalogs }) => {
            catalogs.forEach(({ id, price_list }) => {
              this.catalogId = id
              price_list.forEach(item => {
                this.priceList.push({
                  price_area_id: item.price_area_id,
                  customer_category_id: item.customer_category_id,
                  currency: item.currency,
                  value: item.selling_price,
                  uom: item.uom,
                  effective_date: parseDate(item.effective_date),
                  min_qty: 1,
                  price_qty: 1,
                })
              })
            })
          })
        })
        .catch(err => console.error(err))
        .then(() => this.isLoading = false)
    },
    async fetchArea(search = '') {
      this.areas = []
      const bodyValue = {
        id: this.$route.params.id,
        params: {
          business_id: this.$route.query.business_id,
          search,
          page: 1,
          limit: 100,
          sort: 'priceAreaName',
          order: 'createdAt',
        },
      }
      await getArea(bodyValue)
        .then(({ data: { data: response }}) => {
          this.areas = response
        })
        .catch(err => console.error(err))
    },
    async fetchCustomer() {
      this.customers = []
      await getCustomerCategory({ business_id: this.$route.query.business_id })
        .then(({ data: { content: response } }) => {
          this.customers = response
        })
        .catch(err => console.error(err))
    },
    async onSubmitPrice(value) {
      this.onCloseModal()
      this.isLoading = true
      const { business_id, workspace_id } = this.$route.query
      const price = value.map(item => ({
        ...item,
        value: +item.value,
        effective_date: this.$moment(item.effective_date).format('YYYY-MM-DD'),
      }))
      const bodyValue = {
        workspace_id,
        id: this.$store.state.productChannel.productId,
        data: {
          business_id,
          product_id: this.$store.state.productChannel.productId,
          catalog_id: this.catalogId,
          price,
        },
      }
      await updatePriceArea(bodyValue)
        .then(() => this.fetchPriceArea())
        .catch(err => console.error(err))
        .then(() => this.isLoading = false)
    },
    getName(id, type) {
      const result = type === 'areas' ? 'price_area_name' : 'category_name'
      const name = this[type].find(item => item.id === id)
      return name[result] || '-'
    },
    onOpenModal() {
      this.visible = true
    },
    onCloseModal() {
      this.visible = false
    },
  },
}
</script>
