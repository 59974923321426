<script>
import { defineComponent, ref, watch } from 'vue' // computed, 
import Checkbox from '@/components/Input/Checkbox.vue'
import PriceFormulaInput from './PriceFormulaInput.vue'
import FormItem from '@/components/Input/FormItem.vue'
import PriceInput from './PriceInput.vue'
import isEqual from 'lodash/isEqual'
import PriceArea from './PriceArea.vue'

export default defineComponent({
  name: 'PriceIndex',
  components: {
    Checkbox,
    PriceFormulaInput,
    FormItem,
    PriceInput,
    PriceArea,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    /** @type {import('@/types/product').ProductPrice} */
    value: {
      type: Object,
      required: true,
    },
    catalog: {
      type: Object,
      default: () => {},
    },
    model: {
      type: Object,
      default: () => {},
    },
    units: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
    },
    vatType: {
      type: String,
      default: () => 'INCLUDED',
    },
    vatAmount: {
      type: Number,
      default: () => 0.11,
    },
    label: {
      type: String,
      default: () => 'Harga',
    },
    flag: {
      type: String,
      default: () => '',
    },
    unit: {
      type: String,
      default: () => undefined,
    },
  },
  setup(props, { emit }) {
    const price = ref(() => props.value)
    watch(() => props.value, (value) => {
      price.value = value
    }, { immediate: true })
    watch(price, (value, old) => {
      if(!isEqual(value, old)) {
        emit('input', value)
      }
    }, { deep: true })

    const lastNonFormulaValue = ref(null)
    const usePriceFormula = ref(!!props.value.formula_id)
    watch(() => props.value.formula_id, (value) => {
      usePriceFormula.value = !!value
    })
    watch(usePriceFormula, (value, previousValue) => {
      if (!previousValue) {
        lastNonFormulaValue.value = price.value.formula_id ? null : price.value.value || null
      }

      if (!value) {
        price.value.formula_id = null
        price.value.formula_name = null
        price.value.product_variable = []
        price.value.global_variable = []
        price.value.formula_variable = []
        price.value.value = lastNonFormulaValue.value
      } else {
        price.value.value = price.value.formula_id ? price.value.value : null
      }
    })

    return {
      price,
      usePriceFormula,
    }
  },
  computed: {
    isChannel() {
      return this.$store.state.products.channel
    },
  },
})
</script>

<template>
  <div>
    <div v-if="$route.query.edit">
      <a-col class="mb-2" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          {{ $t('product.price') }}
        </div>
      </a-col>
      <a-col class="mb-2" :span="18">
        <div class="mb-2">
          <span v-if="!edit">: {{ price.value }} {{ price.currency }}</span>
          <PriceInput
            v-else
            v-model="price"
            :unit="unit"
            :vat-type="vatType"
            :vat-amount="vatAmount"
            :label="label"
            @changeuom="val => $emit('changeuom', val)"
          />
        </div>
      </a-col>
      <a-col class="mb-2" :span="6">
        <div class="title-detail d-flex justify-content-start mb-2">
          {{ $t('product.unit') }}
        </div>
      </a-col>
      <a-col class="mb-2" :span="18">
        <div class="mb-2">
          : {{ price.uom }}
        </div>
      </a-col>
    </div>
    <div v-else>
      <FormItem v-if="!isChannel" tag="div" class="form-item-no-margin mb-2">
        <Checkbox v-model="usePriceFormula" :label="$t('product.use_formula')" />
      </FormItem>

      <FormItem v-else tag="div" class="form-item-no-margin mb-2">
        <a-radio-group v-model="usePriceFormula" name="priceAreaFormula">
          <a-radio :value="true">
            {{ $t('product.use_formula') }}
          </a-radio>
          <a-radio :value="false">
            Gunakan Harga per Area
          </a-radio>
        </a-radio-group>
      </FormItem>

      <PriceArea v-if="isChannel && !usePriceFormula" />
          
      <PriceInput
        v-if="!isChannel && !usePriceFormula"
        v-model="price"
        :unit="unit"
        :vat-type="vatType"
        :vat-amount="vatAmount"
        :label="label"
        @changeuom="val => $emit('changeuom', val)"
      />

      <FormItem v-if="usePriceFormula">
        <PriceFormulaInput
          v-model="price"
          :business="business"
          :unit="unit"
          :label="label"
          class="mt-4"
          @changeuom="val => $emit('changeuom', val)"
        />
      </FormItem>
    </div>
  </div>
</template>
