<script>
import { defineComponent, ref, computed, nextTick } from 'vue'
import debounce from 'lodash/debounce'
import { useFetch } from '@/composable/useFetch'
import FormItem from '@/components/Input/FormItem.vue'
import FormInputGroup from '@/components/Input/FormInputGroup.vue'

export default defineComponent({
  components: {
    FormInputGroup,
    FormItem,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    product: {
      // /** @type {import('vue').PropType<ProductFormModel} */
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const removeFromBundle = (catalogId) => {
      const items = [...props.value].filter(bundle => bundle.catalog_id !== catalogId)
      emit('input', items)
    }

    const addNewItemModal = ref(false)
    const { execute: fetcher, isLoading, error, data } = useFetch()
    const errorMessage = computed(() => error.value?.response?.data?.message ?? error.value?.response?.statusText ?? error.value?.message)
    const optionHint = ref(null)
    const productSearchList = computed(() => {
      if(optionHint.value) {
        return []
      }

      const list = []
      const arr = data.value?.data ?? []
      arr.forEach(product => {
        product.catalogs.forEach(catalog => {
          list.push({
            product_id: product.id,
            catalog_id: catalog.id,
            title:  catalog.title,
            sku: catalog.sku,
          })
        })
      })

      return list
    })
    const onSearchProduct = debounce(async (keyword) => {
      const q = keyword.trim()

      if (q.length < 2) {
        optionHint.value = "Ketik minimal 2 karakter untuk mencari produk"
        data.value = null
        return
      }

      optionHint.value = null
      const businessId = props.business.business_id
      fetcher(`/product/query`, {
        method: 'GET',
        headers: {
          'Business-Id': businessId,
        },
        params: {
          business_id: businessId,
          limit: 15,
          page: 1,
          q: keyword,
        },
      })
    }, 250)
    const onSelectProductSearchItem = (catalogId) => {
      const items = [...props.value]
      const catalog = productSearchList.value.find((item) => item.catalog_id === catalogId)
      const existing = items.find((item) => item.catalog_id === catalogId)

      if (catalog) {
        if (existing) {
          existing.quantity += 1
          addNewItemModal.value = false
          nextTick(() => {
            const el = document.querySelector(`.bundle-item__${existing.catalog_id}`)
            if (el) {
              el.classList.add('flashing')

              setTimeout(() => el.classList.remove('flashing'), 1800)
            }
          })
        } else {
          items.push({
            quantity: 1,
            catalog_id: catalog.catalog_id,
            product_id: catalog.product_id,
            sku: catalog.sku,
            title: catalog.title,
          })

          addNewItemModal.value = false
        }

        emit('input', items)
      }
    }

    const onChangeQty = (item, value) => {
      const items = [...props.value].map(i => ({...i}))
      const existing = items.find((i) => i.catalog_id === item.catalog_id)

      if (existing) {
        existing.quantity = value
        emit('input', items)
      }
    }

    const baseUnit = computed(() => props.product.units ? props.product.units[0]?.unit : '-' )

    return {
      productSearchList,
      onSearchProduct,
      addNewItemModal,
      onSelectProductSearchItem,
      removeFromBundle,
      isLoading,
      errorMessage,
      baseUnit,
      onChangeQty,
      optionHint,
    }
  },
})
</script>

<template>
  <div>
    <table class="w-100">
      <thead>
        <tr>
          <th>Nama Produk</th>
          <th>Quantity</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-if="!value.length">
          <td colspan="2" class="py-4">
            <a-empty>
              <template slot="description">
                <div class="">
                  Daftar produk bundling diisi
                </div>
              </template>
            </a-empty>
          </td>
        </tr>
        <tr 
          v-for="item in value"
          :key="item.catalog_id"
          :class="{
            [`bundle-item__${item.catalog_id}`]: true,
          }"
        >
          <td>
            <div>{{ item.title }}</div>
            <div style="font-size: 80%; color: #999">
              SKU: {{ item.sku }}
            </div>
          </td>
          <td width="200">
            <FormItem tag="div" class="mb-0 form-item-no-margin">
              <FormInputGroup>
                <a-input-number
                  :value="item.quantity"
                  :min="1"
                  :step="1"
                  class="w-100"
                  @change="(value) => onChangeQty(item, value)"
                />

                <template #append>
                  <div class="ant-input-group-addon">
                    {{ item.uom || baseUnit }}
                  </div>
                </template>
              </FormInputGroup>
            </FormItem>
          </td>
          <td>
            <a-popconfirm
              placement="left" 
              title="Apakah Anda yakin ingin menghapus produk ini dari daftar bundling?"
              @confirm="() => removeFromBundle(item.catalog_id)"
            >
              <a-button class="btn-delete">
                <a-icon type="delete" />
              </a-button>
            </a-popconfirm>
          </td>
        </tr>
      </tbody>
    </table>

    <a-button type="primary" ghost @click.prevent="addNewItemModal = true">
      <a-icon type="plus" />
      Tambah Produk
    </a-button>


    <a-modal v-model="addNewItemModal" :footer="false">
      <template #title>
        <h5 class="mb-0">
          Tambah produk bundling
        </h5>
      </template>

      <div class="font-weight-medium mb-2">
        Cari produk
      </div>
      <div class="product-search-field">
        <a-auto-complete
          :key="addNewItemModal"
          class="w-100 h-48px"
          auto-focus
          placeholder="Masukkan judul produk / SKU"
          @search="onSearchProduct"
          @select="onSelectProductSearchItem"
        >
          <template #dataSource>
            <template v-if="!optionHint">
              <a-select-option
                v-for="(item) in productSearchList"
                :key="item.catalog_id"
                :value="item.catalog_id"
              >
                <div>{{ item.title }}</div>
                <div class="text-muted text-small" style="font-size: 80%">
                  SKU: {{ item.sku }}
                </div>
              </a-select-option>
            </template>
            <a-select-option v-else key="hint" disabled>
              {{ optionHint }}
            </a-select-option>
          </template>
        </a-auto-complete>
        <a-icon v-if="isLoading" type="loading" class="loader" />
      </div>

      <div v-if="errorMessage" class="ant-form-explain text-danger">
        {{ errorMessage }}
      </div>
    </a-modal>
  </div>
</template>

<style scoped lang="scss">
.product-search-field {
  position: relative;

  .loader {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--kit-color-primary)
  }
}

table {
  width: 100%;
  margin-bottom: 1.5rem;

  th:nth-child(1),
  th:nth-child(2) {
    background-color: #f8f8f8;
  }

  th:nth-child(3) {
    width: 30px;
  }

  td:nth-child(1),
  td:nth-child(2),
  th:nth-child(1),
  th:nth-child(2) {
    padding: .5rem .75rem;
    border: 1px solid #f1f1f1;
  }
  .ant-input-group-wrapper {
    border: 2px solid transparent;
  }
}

.btn-delete {
  border: 0;

  &:hover,
  &:active,
  &:focus {
    color: var(--danger);
  }
}

.flashing {
  padding: 0px;

  .ant-input-group-wrapper {
    transition: all .2s ease-in;
    border-radius: 6px;
    animation: blink .3s step-end infinite alternate;
  }
}

@keyframes blink {
  50% {
    border-color: var(--danger);
  }
}
</style>
