<template>
  <a-modal
    centered
    :width="1024"
    :mask-closable="false"
    :dialog-style="dialogStyle"
    :visible="visible"
    :title="$t('product.price_area')"
    :footer="null"
    @cancel="onCancel"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
    >
      <div class="form-edit">
        <a-row
          v-for="(price, index) in form.price"
          :key="index"
          :gutter="16"
          type="flex"
          align="bottom"
          class="pb-3 border-b-gray"
        >
          <a-col :span="7">
            <a-form-model-item
              :label="$t('product.area')"
              :rules="rules.price_area_id"
              :prop="'price.' + index + '.price_area_id'"
              class="mb-0"
            >
              <a-select
                v-model="price.price_area_id"
                placeholder="Cari & Pilih Area"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                @change="onChange('areas')"
                @search="onSearch($event, 'areas')"
              >
                <a-select-option v-for="area in filteredArea" :key="area.id">
                  {{ area.price_area_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item
              :label="$t('product.customer_type')"
              :rules="rules.customer_category_id"
              :prop="`price.${index}.customer_category_id`"
              class="mb-0"
            >
              <a-select
                v-model="price.customer_category_id"
                placeholder="Cari & Pilih Tipe Pelanggan"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                @change="onChange('customers')"
                @search="onSearch($event, 'customers')"
              >
                <a-select-option v-for="customer in filteredCustomer" :key="customer.id">
                  {{ customer.category_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item
              :label="$t('product.price')"
              :rules="rules.value"
              :prop="`price.${index}.value`"
              class="mb-0"
            >
              <a-input
                v-model="price.value"
                :placeholder="$t('product.price')"
              >
                <a-select slot="addonBefore" default-value="Rp">
                  <a-select-option value="Rp">
                    Rp
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item
              :label="$t('product.unit')"
              :rules="rules.uom"
              :prop="'price.' + index + '.uom'"
              class="mb-0"
            >
              <a-select
                v-model="price.uom"
                :placeholder="$t('product.unit')"
              >
                <a-select-option v-for="uom in uoms" :key="uom">
                  {{ uom }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item
              :label="$t('product.effective_date')"
              :rules="rules.effective_date"
              :prop="`price.${index}.effective_date`"
              class="mb-0"
            >
              <a-date-picker
                v-model="price.effective_date"
                :format="dateFormat"
                class="w-100"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="2" class="text-center">
            <a-icon
              type="minus-circle"
              class="delete-button"
              @click="onRemovePrice(index)"
            />
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24" class="text-center">
            <a-button
              type="primary"
              class="mt-4"
              ghost
              @click="onAddPrice"
            >
              <a-icon type="plus" />
              {{ $t('product.add_price') }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div class="form-edit-footer">
        <a-form-model-item class="mb-0">
          <a-button type="primary" size="large" ghost @click="onCancel">
            Batal
          </a-button>
          <a-button
            type="primary"
            size="large"
            class="ml-4"
            html-type="submit"
            @click="onSubmit"
          >
            Terapkan
          </a-button>
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
import { rulesPriceArea } from '@/data/rules'

export default {
  name: 'ModalEditPriceArea',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    prices: {
      type: Array,
      default: () => [],
    },
    areas: {
      type: Array,
      default: () => [],
    },
    customers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rules: rulesPriceArea,
      dateFormat: 'DD/MM/YYYY',
      filteredArea: [],
      filteredCustomer: [],
      uoms: ['PCS', 'BOX', 'CTN'],
      dialogStyle: {
        top: '20px',
      },
      form: {
        price: [],
      },
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.filteredArea = JSON.parse(JSON.stringify((this.areas)))
        this.filteredCustomer = JSON.parse(JSON.stringify((this.customers)))
        this.form.price = JSON.parse(JSON.stringify((this.prices)))
      }
    },
  },
  methods: {
    onSearch(value, type) {
      const result = type === 'areas' ? 'filteredArea' : 'filteredCustomer'
      const name = type === 'areas' ? 'price_area_name' : 'category_name'
      this[result] = this[type].filter(item => (
        item[name].toLowerCase().includes(value.toLowerCase())
      ))
    },
    onChange(type) {
      const result = type === 'areas' ? 'filteredArea' : 'filteredCustomer'
      this[result] = JSON.parse(JSON.stringify((this[type])))
    },
    onAddPrice() {
      this.form.price.push({
        price_area_id: undefined,
        customer_category_id: undefined,
        currency: 'IDR',
        value: '',
        uom: 'PCS',
        effective_date: '',
        min_qty: 1,
        price_qty: 1,
      })
    },
    onRemovePrice(index) {
      this.form.price.splice(index, 1)
      if (!this.form.price.length) this.onAddPrice()
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.form.price)
        }
      })
    },
    onCancel() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.border-b-gray {
  border-bottom: 1px solid #e4e9f0
}
.delete-button {
  padding: 11px 0;
  font-size: 20px;
  color: #ff0a54;
  cursor: pointer
}
.form-edit {
  height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.form-edit-footer {
  padding-top: 15px;
  border-top: 1px solid #e4e9f0;
  text-align: right;
}
.ant-form-item-label {
  font-weight: 500 !important;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.ant-modal-footer .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
</style>
