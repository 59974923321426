<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['value'],
  setup() {

  },
})
</script>

<template>
  <a-radio-group
    :value="value"
    class="font-weight-bold"
    @input="(value) => {
      $emit('input', value)
    }"
  >
    <a-radio value="SOFT">
      Soft Bundling
    </a-radio>
    <a-radio value="HARD">
      Hard Bundling
    </a-radio>
  </a-radio-group>
</template>
